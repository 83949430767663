import React, { useState } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import NavigationBar from './components/navbar';

function App() {
  const initialFormData = {
    fullName: '',
    companyName: '',
    email: '',
    message: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [buttonText, setButtonText] = useState('Get Started');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {
      ...formData,
      [name]: value
    };
    setFormData(newFormData);

    // Validate form fields
    const allFieldsFilled = Object.values(newFormData).every(field => field.trim() !== '');
    setIsFormValid(allFieldsFilled);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) return;

    emailjs.sendForm('service_9h60n7u', 'template_qzfr3im', e.target, 'STswVZ_5m3gxFIBe3')
      .then((result) => {
        console.log(result.text);
        setButtonText('Submitted!');
        setIsSubmitted(true);
        setFormData(initialFormData); // Reset form fields after successful submission
        setIsFormValid(false); // Reset form validity
      }, (error) => {
        console.log(error.text);
        alert('Failed to send message. Please try again.');
      });
  };

  const scrollToServices = () => {
    const servicesSection = document.getElementById('features');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="App">
      <NavigationBar />
      <section id="home" className="section">
        <h1>Welcome to Gwise Brands</h1>
        <p>At Gwise Brands, we are dedicated to driving innovations that uplift lives and build communities. We are working to achieve this mission through our diverse portfolio of brands, each operating across various sectors and making a positive impact on society. Click here to learn more.</p>
        <button onClick={scrollToServices}>Learn More</button>
      </section>

      <section id="features" className="section">
        <h2>OUR SERVICES</h2>
        <Container>
          <Row>
            <Col md={4} sm={12} className='feature-row'>
              <div className="feature-box">
                <img src={`${process.env.PUBLIC_URL}/images/img1.png`} alt="Description" />
                <h3>Enterprise Development</h3>
                <p>At Gwise Brands, we offer business planning services, market feasibility studies, business and financial plan development, competition analysis, grant applications and general engagement to ensure entrepreneurial success.</p>
              </div>
            </Col>
            <Col md={4} sm={12} className='feature-row'>
              <div className="feature-box">
                <img src={`${process.env.PUBLIC_URL}/images/img2.png`} alt="Description" />
                <h3>Project Management Consulting</h3>
                <p>We provide expert project management consulting in various sectors, ensuring projects are completed on time, within budgets and to the highest standards. We offer IT project management, data management and analysis as well as real estate project management.</p>
              </div>
            </Col>
            <Col md={4} sm={12} className='feature-row'>
              <div className="feature-box">
                <img src={`${process.env.PUBLIC_URL}/images/img3.png`} alt="Description" />
                <h3>Training Hub</h3>
                <p>We deliver an inclusive learning journey, equipping entrepreneurs and individuals with the skills and knowledge they need to succeed on the global stage. We offer training on business planning, pitch training and tech skills.</p>
              </div>
            </Col>
          </Row>
          <Row className='feature-row-2'>
            <Col md={4} sm={12} className='feature-row'>
              <div className="feature-box">
                <img src={`${process.env.PUBLIC_URL}/images/img4.png`} alt="Description" />
                <h3>Business and Investment Advisory</h3>
                <p>Our advisory services are tailored to provide insightful business and investment advice, helping clients make informed decisions to drive growth and profitability.</p>
              </div>
            </Col>
            <Col md={4} sm={12} className='feature-row'>
              <div className="feature-box">
                <img src={`${process.env.PUBLIC_URL}/images/img5.png`} alt="Description" />
                <h3>General Contracts and Services</h3>
                <p>We also offer professional sourcing and regulation services, ensuring our clients get the best deals and resources for their business needs.</p>
              </div>
            </Col>
            <Col md={4} sm={12} className='feature-row'>
              <div className="feature-box">
                <img src={`${process.env.PUBLIC_URL}/images/img6.png`} alt="Description" />
                <h3>Climate Intelligence Solutions</h3>
                <p>We provide Climate intelligence solutions for businesses. We offer data-driven insights, strategic guidance, and capacity building to help organizations build resilience, mitigate climate risks, and seize emerging opportunities</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="pricing" className="section">
        <div className='info-details'>
          <Container>
            <h2 className="text-center">Driving Success</h2>
            <Row className="text-center info-text">
              <Col md={3} sm={6} xs={6}>
                <h3>7+</h3>
                <p>Years of Experience</p>
              </Col>
              <Col md={3} sm={6} xs={6}>
                <h3>500+</h3>
                <p>Successful Projects</p>
                <p>(B2B projects)</p>
              </Col>
              <Col md={3} sm={6} xs={6}>
                <h3>153+</h3>
                <p>Satisfied Clients</p>
              </Col>
              <Col md={3} sm={6} xs={6}>
                <h3>10</h3>
                <p>Industry Partners</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Row className='row-no-gutters'>
            <Col className='about-us' md={6} sm={12}>
              <div>
                <h2>ABOUT US</h2>
                <h3>Empowering Business to Excel</h3>
                <p>Gwise Brands Limited is dedicated to empowering individuals and businesses to achieve their goals and foster economic growth. Our team of professionals brings a wealth of expertise in business management, project execution, and corporate training. We are committed to driving sustainable success for our clients in Africa and beyond.</p>
              </div>
            </Col>
            <Col className='about-us-img' md={6} sm={12}>
            </Col>
          </Row>
        </div>
      </section>

      <section id="team" className="section">
        <h2>MEET THE TEAM</h2>
        <Row className='team-row' gy-7>
          <Col sm={12} md={6} className='team-col'>
            <div className='team-col-inner' style={{ paddingBottom: '92px' }}>
              <img src={`${process.env.PUBLIC_URL}/images/samuel.jpg`} alt="Description" />
              <h3>Samuel Adewumi</h3>
              <h6>Co-founder</h6>
              <p>Samuel brings a unique blend of environmental science knowledge (University of Lagos) and business development expertise (certified by Enterprise Development Centre, PAU Lagos and Harvard Kennedy School). His leadership and PDIA (Problem-Driven Iterative Adaptation) skills have been instrumental in GwiseBrands' success since 2019.</p>
              <p><span className='strengths'>Key Strengths: </span>Environmental science background, business development expertise, PDIA leadership.</p>
            </div>
          </Col>
          <Col sm={12} md={6} className='team-col'>
            <div className='team-col-inner'>
              <img src={`${process.env.PUBLIC_URL}/images/regland.jpg`} alt="Description" />
              <h3>Regland Onyemeka</h3>
              <h6>Co-founder</h6>
              <p>Regland is a data analytics powerhouse, transforming raw data into actionable insights for various sectors. His expertise spans the entire data lifecycle, from collection and cleaning to analysis and visualization, using tools like Python, R, SQL, and Power BI. Over seven years of management experience honed his learning, development, program management, and business growth skills, enabling effective team collaboration and stakeholder communication. He has an M.Sc with several certifications from EDC-PAU, Microsoft, Google, Erasmus Centre for Entrepreneurship, Netherland etc.</p>
              <p><span className='strengths'>Key Strengths: </span>Data transformation maestro, tech toolkit proficiency, project showcase, live analyses, published research.</p>
            </div>
          </Col>
        </Row>
        <div className='additional-team'>
          <h3>Additional Team Members</h3>
          <p>GwiseBrands is further strengthened by experienced team members with diverse experience in enterprise development, market leadership, data analytics, climate intelligence solution, project management, accounting, business formulation to give our clients the best of services and a well-rounded approach to supporting businesses in Africa.</p>
        </div>
      </section>

      <section id="testimonials" className="section">
        <h2>TESTIMONIALS</h2>
        <Carousel className="testimonial-carousel" indicators={true} interval={4000}>
          <Carousel.Item className="testimonial-item">
            <h2>Empowering Entrepreneurs</h2>
            <p>" At Gwise Brands, we are proud of our track record in empowering entrepreneurs "</p>
          </Carousel.Item>
          <Carousel.Item className="testimonial-item">
            <p>" By March 2024, we have successfully supported 91 early-stage entrepreneurs, providing them with the structure and mentorship needed to access $455,000 in seed grants "</p>
          </Carousel.Item>
          <Carousel.Item className="testimonial-item">
            <p>" We have also trained over 500 entrepreneurs across Africa on various business topics "</p>
          </Carousel.Item>
          <Carousel.Item className="testimonial-item">
            <p>" Our commitment to their success drives our continuous efforts to support more entrepreneurs across Africa "</p>
          </Carousel.Item>
        </Carousel>
        <button className='testimonial-btn'>
          <a href="https://www.google.com/search?sca_esv=247eb50602a83f19&sca_upv=1&sxsrf=ADLYWIKp8kOJIeZt0mT0ClLpfsY6BDPPCg%3A1722008324274&q=GwiseBrands&stick=H4sIAAAAAAAAAONgU1I1qDA0MDFPNTUztTQwN00zMk2xMqgwTTEzNTSzTDYztEw2SDU1WMTK7V6eWZzqVJSYl1IMAGQk6UI3AAAA&mat=Cd-VXwvKLgoU&ved=2ahUKEwjAzN_zhMWHAxWZW0EAHXAQFQ4QrMcEegQIFBAD#mpd=~1444018075834750431/customers/reviews">
            View Reviews
          </a>
        </button>
      </section>

      <section id="contact" className="section">
        <Container>
          <Row>
            <Col md={6} className="contact-left">
              <h2>CONTACT US</h2>
              <h3>Get in touch with us</h3>
              <p>Ready to take your dreams to the next level? Contact Gwise Brands today and let us help you achieve your goals.</p>
              <div className='mobile-number'>
                <p>08131089714</p>
                <p>08060429226</p>
              </div>
              <div className="social-icons">
                <a href="https://web.facebook.com/GwiseBrands/"><i className="fab fa-facebook"></i></a>
                <a href="https://www.instagram.com/gwisebrand/"><i className="fab fa-instagram"></i></a>
                <a href="https://www.tiktok.com/@gwisebrands"><i className="fab fa-tiktok"></i></a>
                <a href="https://www.youtube.com/@gwisebrand"><i className="fab fa-youtube"></i></a>
              </div>
            </Col>
            <Col md={6} className="contact-right">
              <h4>FILL THE FORM</h4>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label>Full Name</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        name="fullName" 
                        value={formData.fullName}
                        onChange={handleChange} 
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label>Company Name</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        name="companyName" 
                        value={formData.companyName}
                        onChange={handleChange} 
                      />
                    </div>
                  </Col>
                </Row>
                <div className="form-group">
                  <label>Email *</label>
                  <input 
                    type="email" 
                    className="form-control" 
                    name="email" 
                    value={formData.email}
                    onChange={handleChange} 
                  />
                </div>
                <div className="form-group">
                  <label>Leave us a message...</label>
                  <textarea 
                    className="form-control" 
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button type="submit"  disabled={!isFormValid}>{buttonText}</button>
              </form>
            </Col>
          </Row>
        </Container>
      </section>

      <footer>
        <p>© 2024 Gwise Brands Limited. All rights reserved. | Privacy Policy | Terms of Service</p>
      </footer>
    </div>
  );
}

export default App;
