import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link as ScrollLink } from 'react-scroll';

const NavigationBar = () => {
  return (
    <Navbar expand="lg" variant="light" className='site-nav'>
      <Navbar.Brand href="#">
        <img
          src={`${process.env.PUBLIC_URL}/images/GwiseBrands.png`}
          alt="Logo"
          id='nav-brand'
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-start">
        <Nav className="mx-auto">
          <Nav.Item>
            <ScrollLink
              to="home"
              smooth={true}
              duration={500}
              className="nav-link"
              activeClass="active"
              spy={true}
              offset={-70}
            >
              Home
            </ScrollLink>
          </Nav.Item>
          <Nav.Item>
            <ScrollLink
              to="features"
              smooth={true}
              duration={500}
              className="nav-link"
              activeClass="active"
              spy={true}
              offset={-70}
            >
              Services
            </ScrollLink>
          </Nav.Item>
          <Nav.Item>
            <ScrollLink
              to="pricing"
              smooth={true}
              duration={500}
              className="nav-link"
              activeClass="active"
              spy={true}
              offset={-70}
            >
              About
            </ScrollLink>
          </Nav.Item>
          <Nav.Item>
            <ScrollLink
              to="team"
              smooth={true}
              duration={500}
              className="nav-link"
              activeClass="active"
              spy={true}
              offset={-70}
            >
              Team
            </ScrollLink>
          </Nav.Item>
          <Nav.Item>
            <ScrollLink
              to="testimonials"
              smooth={true}
              duration={500}
              className="nav-link"
              activeClass="active"
              spy={true}
              offset={-70}
            >
              Testimonials
            </ScrollLink>
          </Nav.Item>
          <Nav.Item>
            <ScrollLink
              to="contact"
              smooth={true}
              duration={500}
              className="nav-link"
              activeClass="active"
              spy={true}
              offset={-70}
            >
              Contact
            </ScrollLink>
          </Nav.Item>
        </Nav>
        <Button href="tel:+2348131089714" style={{backgroundColor:'#0038ab'}} className="ml-auto nav-button">Call Now</Button>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
